import password_logo from "../../login/img/sat-logo.svg";
import close_icon from "./img/majesticons_close.svg";
import qr_image from "./img/login-qr.png";
import { useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";
import styles from "./QRForm.module.scss";
import NoticePopup from "../NoticePopup/NoticePopup";
import { logout } from "../../app/services/auth.service";
import { NavLink, useNavigate } from "react-router-dom";
import { useGetProfileInfoDataQuery } from "../../app/services/profile";
import { withNamespaces } from "react-i18next";
import { T } from "lodash/fp";
import { change_axios_language } from "../../app/services/api";
const QRFormPage = (props:any) => {
  const {logoData,t}=props;
  const navigate = useNavigate();
  const { data: profile } = useGetProfileInfoDataQuery();
  const [qr_in,setQRIn]=useState<string>('');
  const [qr_out,setQROut]=useState<string>('');
  const [employeeID,setEmployeeID]=useState<string>('');
  const [name,setName]=useState<string>('');
  const [activeButton, setActiveButton] = useState<string>("ClockIn");
  // var userData:any = localStorage.getItem("userData");
  // var userJson: any = {};
  // var qr_in_code='';
  // var qr_out_code='';
  // if (userData) {
  //   userJson = JSON.parse(userData);
  //   userJson.token = userJson.accessToken;
  //   qr_in_code=userJson?.user?.employee?.qr_in_code;
  //   qr_out_code=userJson?.user?.employee?.qr_out_code;
  // }
  useEffect(()=>{
    if(profile){
      const pData:any=profile;
      setQROut(pData?.employee?.qr_out_code)
      setQRIn(pData?.employee?.qr_in_code);
      setEmployeeID(pData?.employee?.employeeID)
      setName(pData?.employee?.name)
    }
  },[profile])
  useEffect(()=>{
    change_axios_language();
    console.log('d',t("in") , t("out"))
  },[])
  return (
    <div className={twJoin("max-w-full h-[92vh] overflow-hidden bg-white md:max-w-[360px] mx-auto")}>
      <div className="max-w-full mx-auto">
        <div className="change-password-container px-5 ">
          <div className="py-5 relative left-0 w-full top-0 bg-white">
            <h3 className="heading3 font-secondary text-center text-sat_darkblackv2 w-full">
              {t("my_qr_code")}
            </h3>
            <NavLink to={{pathname:"/profile"}} className="absolute right-5 top-1/2 -translate-y-1/2">
              <img src={close_icon} alt="back" className="" />
            </NavLink>
          </div>
          <div className="pt-8 pb-8 h-auto flex flex-col justify-between">
            <div>
              <img
                src={logoData?.logo?logoData?.logo:password_logo}
                alt="password_logo"
                className="mx-auto max-w-full"
              />
              <div className="mt-5">
                <div
                  className={twJoin(
                    styles.clock_in_out_buttons,
                    "grid grid-cols-2 gap-[14px] mb-8"
                  )}
                >
                  <button
                    onClick={() => setActiveButton("ClockIn")}
                    className={twJoin(
                      activeButton == "ClockIn"
                        ? "bg-sat_primary text-white"
                        : "bg-white text-sat_grayHint",
                      "rounded-lg py-3 px-5  text-center font-bold text-button"
                    )}
                  >
                    {t("clock_in")}
                  </button>
                  <button
                    onClick={() => setActiveButton("ClockOut")}
                    className={twJoin(
                      activeButton == "ClockOut"
                        ? "bg-sat_primary text-white"
                        : "bg-white text-sat_grayHint",
                      "rounded-lg py-3 px-5 text-center font-bold text-button"
                    )}
                  >
                    {t("clock_out")}
                  </button>
                </div>
                <div className="border border-sat_border rounded-xl bg-white p-5 block w-full">
                  <div
                    className={twJoin(
                      activeButton == "ClockIn"
                        ? "border-sat_greenlight"
                        : "border-sat_red_light",
                      "p-[14px] border-4  bg-white block relative rounded-xl"
                    )}
                  >
                    <div className="">
                      <img
                        src={activeButton == "ClockIn"?qr_in?qr_in:qr_image:qr_out?qr_out:qr_image}
                        alt="qr_image"
                        className="max-w-full object-cover w-full mx-auto"
                      />
                    </div>
                  </div>
                  <div className="text-left mt-3 flex items-center justify-between">
                    <div>
                      <p className="text-body-23 text-sat_darkblackv2 font-bold">
                        {name}
                      </p>
                      <p className="text-button text-sat_graydark font-normal">
                      {employeeID}
                      </p>
                    </div>
                    <div>
                      <p
                        className={twJoin(
                          activeButton == "ClockIn"
                            ? "bg-sat_greenDark"
                            : "bg-sat_primary",
                          "text-white px-3 py-1 rounded-[13px] font-bold text-body-23"
                        )}
                      >
                        {activeButton == "ClockIn" ? t("in") : t("out")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>      
    </div>
  );
};
export default withNamespaces((props) => props.namespaces)(QRFormPage);
