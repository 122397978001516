import { useEffect, useState } from "react";
import "./App.scss";
import MainRouter from "./MainRouter";
import {
  //requestPermission,
  serverkey,
} from "./app/services/firebase";
import { getAuthUser } from "./app/services/dashboard";
import { useNavigate } from "react-router-dom";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import "./i18n";
import { withNamespaces } from "react-i18next";
import axios from "./axios";
import { endpointUrl } from "./app/services/api";
import { SafeArea } from "capacitor-plugin-safe-area";
import { PrivacyScreen } from "@capacitor-community/privacy-screen";
import { App as CapacitorApp } from "@capacitor/app";
import BlurOverlay from "./components/blur/BlurOverlay";
function App() {
  const [isBlurVisible, setIsBlurVisible] = useState(false);

  useEffect(() => {
    const handleAppStateChange = (event: any) => {
      // console.log('change ',event)
      if (event.isActive) {
        // App is active
       // setIsBlurVisible(false);
      } else {
        // App is in background
       // setIsBlurVisible(true);
      }
    };

    // Add event listener
    //CapacitorApp.addListener("appStateChange", handleAppStateChange);

    return () => {
      // Clean up the listener on unmount
     // CapacitorApp.removeAllListeners();
    };
  }, []);
  const enable = async () => {
    await PrivacyScreen.enable();
  };

  const disable = async () => {
    console.log("disable screen capture");
    await PrivacyScreen.disable().then((res) => {
      console.log("disable screen capture result", res);
    });
  };
  const navigate = useNavigate();
  // console.log("app div", window, navigator, isSupported());
  FirebaseMessaging.requestPermissions().then((res) => {});
  FirebaseMessaging.addListener("notificationReceived", function (payload) {
    console.log("notificationReceived " + JSON.stringify(payload));
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      link: payload.notification.link,
      id: payload.notification.id,
    };
  });
  FirebaseMessaging.addListener(
    "notificationActionPerformed",
    function (action) {}
  );
  function callChannel(token: any) {
    var getUser = getAuthUser();
    if (getUser?.token) {
      axios(endpointUrl + "admin/fcm-token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUser.token}`,
        },
        data: {
          token: token,
        },
      }).then((res) => {
        // console.log("res ", res)
      });
    }
  }

  useEffect(() => {
    var getUser = getAuthUser();
    if (getUser?.token) {
      //requestPermission();
      FirebaseMessaging.getToken({ vapidKey: serverkey }).then((res) => {
        console.log("getToken " + JSON.stringify(res));
        callChannel(res.token);
      });
    }

    // const unsubscribe = onMessageListener().then((payload:any) => {
    //   setNotification({
    //     title: payload?.notification?.title,
    //     body: payload?.notification?.body,
    //   });
    //   // toast.success(`${payload?.notification?.title}: ${payload?.notification?.body}`, {
    //   //   duration: 60000,
    //   //   position: 'top-right',
    //   // });
    // });
    // return () => {
    //   unsubscribe.catch((err) => console.log('failed: ', err));
    // };
    // console.clear();
    const login = document.querySelector(".login-form");
    const body = document.querySelector("body");
    if (body != null) {
      if (!login) {
        body.style.paddingTop = "0px";
      }
    }
    //disable();
  }, []);
  const safeArea = async () => {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      const ack: any = document.querySelector(".notice-board-acknowledge");
      const body = document.querySelector("body");
      const body_content: any = document.querySelector(".body-content");
      const fixedbar: any = document.querySelector(".fixed-nav-bar");
      const bottom_bar: any = document.querySelector(".bottom-navigation");
      const login = document.querySelector(".login-form");
      if (body != null) {
        if (!login) {
          body.style.paddingTop =
            (insets ? (insets.top == 0 ? 20 : insets.top) : 20) + "px";
        }
      }
      if (fixedbar) {
        fixedbar.style.paddingTop =
          (insets ? (insets.top == 0 ? 20 : insets.top) : 20) + "px";
      }

      if (bottom_bar != null) {
        //bottom_bar.style.paddingBottom = (insets ? insets.bottom : 20) + "px";
        bottom_bar.style.paddingBottom = "20px";

        if (ack) {
          ack.style.bottom =
            insets.bottom + (bottom_bar ? bottom_bar.clientHeight : 0) + "px";
        }
      }
      if (body_content != null) {
        body_content.style.paddingBottom = (insets ? insets.bottom : 20) + "px";
      }
    });

    SafeArea.getStatusBarHeight().then((statusBarHeight: any) => {
      const body = document.querySelector("body");
      const fixedbar: any = document.querySelector(".fixed-nav-bar");
      const bottom_bar: any = document.querySelector(".bottom-navigation");
      // if(body!=null){
      //   body.style.paddingTop=(statusBarHeight?(statusBarHeight+20):0)+'px';
      // }
      // if(fixedbar){
      //   fixedbar.style.paddingTop=(statusBarHeight?statusBarHeight:20)+'px';
      // }
    });

    await SafeArea.removeAllListeners();

    // when safe-area changed
    await SafeArea.addListener("safeAreaChanged", (data) => {
      const { insets } = data;
      for (const [key, value] of Object.entries(insets)) {
        document.documentElement.style.setProperty(
          `--safe-area-inset-${key}`,
          `${100}px`
        );
      }
    });
  };
  safeArea();
  //disable();
  return (
    <div className="App">
      <MainRouter />
      <BlurOverlay isBlurVisible={isBlurVisible} />
    </div>
  );
}
export default withNamespaces()(App);
