import setting_icon from "./img/uil_setting.png";
import noti_icon from "./img/noti_icon.svg";
import FixedBottomNavigation from "../BottomNavigation/BottomNavigation";
import i18n from "../../i18n";
import { withNamespaces } from "react-i18next";
import { useGetProfileInfoDataQuery } from "../../app/services/profile";
import { createContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../app/services/firebase";
import { getMessaging, onMessage } from "firebase/messaging";
import { useGetNoticationsDataQuery } from "../../app/services/notifications";
import { useGetLogoDataQuery } from "../../app/services/cms";
import { BackIcon, SettingIcon1 } from "../common/Icons";
import { getLanguageCodeForbackend } from "../../app/services/api";
export const NotificationContext = createContext({});
export const ProfileContext = createContext({});
export const LogoContext = createContext({});
const UserProfile = (props: any) => {
  const navigate = useNavigate();
  const { data: logo, isLoading: logoLoading } = useGetLogoDataQuery();
  const [notificationList, setNotificationList] = useState<any>([]);
  const [logoData, setLogoData] = useState<any>([]);
  const { data: profile } = useGetProfileInfoDataQuery();
  const { data: notificationsData } = useGetNoticationsDataQuery(getLanguageCodeForbackend());
  const [profileData, setProfileData] = useState<any>("");
  let anno_count = notificationList?.notificationList?.filter(
    (x: any) => x.is_read == false
  )?.length;
  let news_count = notificationList?.news?.filter(
    (x: any) => x.is_read == false
  )?.length;
  let yesterday_count = notificationList?.yesterdayList?.filter(
    (x: any) => x.is_read == false
  )?.length;
  let noti_count =
    (anno_count ?? 0) + (news_count ?? 0) + (yesterday_count ?? 0);
  const [noticount, setNotiCount] = useState<number>(noti_count);
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  onMessage(messaging, function (payload) {
    noti_count = noticount + 1;
    setNotiCount(noti_count);
  });
  useEffect(() => {
    if (profile) {
      setProfileData(profile);
    }
  }, [profile]);
  useEffect(() => {
    if (logo) {
      setLogoData(logo);
    }
  }, [logo]);
  const language = localStorage.getItem("language");
  useEffect(() => {
    i18n.changeLanguage(language ? language : "en");
  }, []);
  const changeProfileData = (data: any) => {
    setProfileData(data);
  };
  useEffect(() => {
    if (notificationsData) {
      setNotificationList(notificationsData);
    }
  }, [notificationsData]);
  useEffect(() => {
    anno_count = notificationList?.notificationList?.filter(
      (x: any) => x.is_read == false
    )?.length;
    news_count = notificationList?.news?.filter(
      (x: any) => x.is_read == false
    )?.length;
    yesterday_count = notificationList?.yesterdayList?.filter(
      (x: any) => x.is_read == false
    )?.length;
    noti_count = (anno_count ?? 0) + (news_count ?? 0) + (yesterday_count ?? 0);
    setNotiCount(noti_count);
  }, [notificationList]);
  const changeNotificationData = (data: any) => {
    setNotificationList(data);
  };

  return (
    <div className="max-w-full">
      <div className="max-w-full mx-auto overflow-hidden md:max-w-[360px]">
        <div className="mb-[66px]">
          <div className="max-w-full md:max-w-[360px] fixed-nav-bar flex items-center justify-between w-full px-5 fixed top-0 left-1/2 -translate-x-1/2 py-4 z-10 bg-white">
            {props.isBack == true ? (
              <NavLink to={{}} onClick={() => navigate(-1)}>
                <BackIcon />
              </NavLink>
            ) : (
              <NavLink to={{ pathname: "/setting" }}>
                <SettingIcon1 />
              </NavLink>
            )}
            <h1 className="heading2 font-normal text-center">{props?.title}</h1>
            <NavLink to={{ pathname: "/notifications" }} className="relative">
              <img src={noti_icon} alt="noti_icon" />
              <div
                className={twJoin(
                  noticount <= 0 ? "hidden" : "",
                  "bg-sat_primary inline-block rounded-full aspect-square absolute right-0 top-0 p-[2px] w-[12px] h-[12px]"
                )}
              >
                <span className="text-white text-[9px] font-bold block leading-[100%] tracking-[-0.18px] whitespace-nowrap">
                  {noticount}
                </span>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="mt-5 body-content">
          <div className="mb-16">
            <LogoContext.Provider value={{ logoData }}>
              <ProfileContext.Provider
                value={{ profileData, changeProfileData }}
              >
                <NotificationContext.Provider
                  value={{ notificationList, changeNotificationData }}
                >
                  {props?.children}
                </NotificationContext.Provider>
              </ProfileContext.Provider>
            </LogoContext.Provider>
          </div>
        </div>
        <div className="px-5 mt-4">
          <FixedBottomNavigation />
        </div>
      </div>
    </div>
  );
};
export default withNamespaces((props) => props.namespaces)(UserProfile);
